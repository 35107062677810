.login__container {
  width: 100%;
  display: flex;
  transition: 300ms;
  padding-top: 72px;
  // padding-bottom: 72px;
  padding-bottom: 316px;
}

.login__content {
  display: flex;
  flex-direction: column;
  transition: 300ms;
  align-items: center;
}

.login__registration {
  margin-bottom: 48px;
}