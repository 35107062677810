.profile__container {
  width: 100%;
  display: flex;
  transition: 300ms;
  padding-top: 70px;
}
.profile__content {
  display: flex;
  flex: 10;
  align-items: flex-start;
  transition: 300ms;
  padding-bottom: 244px;
}

.profile__right-content {
  display: flex;
  flex-direction: column;

  &.view {
    gap: 66px;
  }
}

.profile__right-menuItem {
  margin-bottom: 12px;
}

.profile__right-menuItem {
  margin-left: 80px;
}

.profile__right-wrapper {
  padding-top: 32px;
  display: flex;
}

.profile__right-wrapper-large {
  padding-top: 72px;
  display: flex;
}

.profile__right-title-flex {
  display: flex;
}

.profile__userinfo {
  display: flex;
  align-items: center;
}

.profile__avatar {
  margin-right: 40px;
}

// .profile__about {
//   margin-right: 21px;
// }

.profile__about-name {
  font-family: "PT-Sans-Caption-Bold";
  font-size: 18px;
  line-height: 24px;
  color: $gray-10;
}

.profile__about-email {
  font-family: "PT-Sans-Regular";
  font-size: 16px;
  line-height: 20px;
  color: $gray-40;
}

.profile__edit {
  height: 10px;
  margin-left: 21px;
  align-items:center;
}

.profile__notification {
  // margin-top: 66px;
}

.profile__notification-block {
  display: flex;
  justify-content: space-between;
}

.profile__notification-title {
  margin-bottom: 20px;
}

.profile__checkboxes {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.profile__checkbox {
  display: flex;
  // margin-bottom: 16px;
}

.profile__button-group {
  display: flex;
  justify-content: space-between;
  gap: 36px;
}

.profile__download-area {
  width: 396px;
  border: 2px dashed #c8c5d0;
  border-radius: 6px;
}

.profile__download-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 17px;
  margin-bottom: 18px;
}

.profile__navigation-links {
  margin-bottom: 20px;
}

.arrow-icon {
  margin-left: 8px;
  margin-right: 8px;
}

.profile__avatar-img {
  background: url('./Avatar.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 72px;
  height: 72px;
}
