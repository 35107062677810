.sidemenu__left-wrapper {
  width: 341px;
  height: 750px;
  white-space: nowrap;
}

.sidemenu__left-content {
  margin-left: 16px;
  width: 267px;
  margin-top: 70px;
}

.sidemenu__menu-item {
  display: flex;
  padding: 15px 36px;
}

.sidemenu__menu-item:hover {
  background-color: $violet-90;
  padding: 15px 36px;
  border-radius: 2px;
  transition: 500ms;
  cursor: pointer;
}

.sidemenu__menu-text:hover {
  font-family: "PT-Sans-Caption-Bold";
  color: $violet-20;
  transition: 500ms;
}

.sidemenu__menu-icon {
  margin-right: 14px;
  display: flex;
  align-items: center;
}

.sidemenu__menu-text {
  display: flex;
  align-items: flex-end;
}
