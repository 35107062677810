@import "../../../../pages/style-guide.scss";

.customer-advantages__container {
  display: flex;
  justify-content: space-between;
  padding: 78px 0 108px 0;
  flex-wrap: wrap;
  scroll-margin-top: 78px;
}

.customer__title {
  font-family: 'PT Sans Caption', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  letter-spacing: 1px;
  color: $violet-40;
}

.customer__subtitle {
  margin-top: 42px;
  font-family: 'PT Sans Caption', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: $gray-10;
}

.customer__left-col {
  width: 24.67%;
  display: flex;
  flex-direction: column;
}

.customer-advantages__img {
  width: 949px;
  height: 872px;
  background-image: url("../../images/customer-advantages.svg");
  background-size: contain;
  background-repeat: no-repeat;
  align-self: flex-end;
}

.customer__desc {
  margin-top: 646px;
  font-family: 'PT Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: $gray-40;
}

@media screen and (max-width: 1919px) and (min-width: 1366px) {
  .customer-advantages__img {
    width: 791px;
    height: 727px;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .customer-advantages__img {
    width: 554px;
    height: 509px;
  }

  .customer__title {
    font-size: 36px;
    line-height: 50px;
  }

  .customer__subtitle{
    margin-top: 30px;
    font-size: 28px;
    line-height: 36px;
  }

  .customer__desc {
    margin-top: 420px;
    font-size: 16px;
    line-height: 22px;
  }
}


@media screen and (max-width: 1024px) and (min-width: 320px) {
  .customer-advantages__container {
    flex-direction: column;
    align-items: center;
    gap: 48px;
  }

  .customer__left-col {
    width: 100%;
  }

  .customer__desc {
    margin-top: 60px;
  }

  .customer-advantages__img {
    width: 100%;
    height: 0;
    padding-bottom: 91.91%;
  }
}

