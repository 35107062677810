@import '../../pages/style-guide.scss';

.input__container{
  display: flex;
  flex-direction: column;
}

.input__content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 48px;
  background: #ffffff;
  padding: 14px 12px;
  border: 1px solid $gray-80;
  border-radius: 6px;
  transition: 300ms;

  &.focused {
    border: 1px solid $violet-60;
  }

  input {
    width: 100%;
    border-radius: 6px;
    color: $gray-10;

    &[readonly] {
      color: $gray-50;
    }

    -webkit-text-fill-color: $gray-10;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-background-clip: text;
      //caret-color: blue;
    }
  }

  input[type='text']{
    font-size: 16px;
  }
}

.input-error{
  border-color: $red-90;
  transition: 300ms;
}

.input__text-error{
  line-height: 16px;
  color: $red-90
}
