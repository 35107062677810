@import "../../../../pages/style-guide.scss";

.info__intro-container {
  display: flex;
  justify-content: space-between;
  padding: calc(80px + 78px) 0 60px 0;
  border-bottom: 1px solid $violet-90;
  flex-wrap: wrap;
}

.info__intro-img {
  display: flex;
  align-self: flex-end;
  width: 871px;
  height: 611px;
  background-image: url("../../images/laptop.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 32px;
}

.info__intro-left-col {
  width: 48.67%;
  display: flex;
  flex-direction: column;
}

.info__intro-title {
  font-family: 'PT Sans Caption', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 56px;
  letter-spacing: 1px;
  color: $violet-40;
}

.info__intro-subtitle {
  margin-top: 42px;
  font-family: 'PT Sans Caption', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: $gray-10;
}

.info__intro-desc {
  width: 83.3%;
  margin-top: 120px;
  font-family: 'PT Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: $gray-40;
}

.info__intro-btn {
  width: 288px;
  margin-top: 168px;
}

@media screen and (max-width: 1919px) and (min-width: 1366px) {
  .info__intro-img {
    width: 642px;
    height: 450px;
  }

  .info__intro-btn {
    margin-top: 56px;
  }

  .info__intro-desc {
    width: 83.3%;
  }
}

@media screen and (max-width: 1365px) and (min-width: 1024px) {
  .info__intro-img {
    width: 469px;
    height: 329px;
  }

  .info__intro-title {
    font-size: 46px;
    line-height: 50px;
  }

  .info__intro-subtitle {
    margin-top: 30px;
    font-size: 28px;
    line-height: 36px;
  }

  .info__intro-desc {
    margin-top: 80px;
    font-size: 16px;
    line-height: 22px;
  }

  .info__intro-btn {
    margin-top: 56px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 320px) {
  .info__intro-container {
    flex-direction: column;
    align-items: center;
    padding: calc(40px + 78px) 0 60px 0;
    border-bottom: 1px solid $violet-90;
    gap: 48px;
  }

  .info__intro-left-col {
    width: 100%;
  }

  .info__intro-desc {
    margin-top: 60px;
  }

  .info__intro-img {
    width: 100%;
    height: 0;
    padding-bottom: 70.15%;
  }

  .info__intro-btn {
    margin-top: 56px;
  }

  .info__intro-title {
    font-size: 40px;
    line-height: 54px;
  }
}

