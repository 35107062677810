@import "../../../../pages/style-guide.scss";

.info__steps-container {
  padding-top: 108px;
}

.info__steps-content {
  display: flex;
  margin-top: 42px;
}

.info__steps-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  &:nth-child(2) {
    padding: 0 0 0 72px;
  }
}

.info__steps-img {
  width: 900px;
  height: 592px;
  background-image: url("../../images/steps-img.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.info__steps-numbers {
  width: 350px;
  height: 268px;
  background-image: url("../../images/steps-numbers.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.info__steps-desc {
  font-family: 'PT Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 38px;
  color: $gray-40;
}

@media screen and (max-width: 1919px) and (min-width: 1366px) {
  .info__steps-img {
    width: 648px;
    height: 426px;
  }

  .info__steps-desc {
    font-size: 20px;
    line-height: 20px;
  }

  .info__steps-numbers {
    width: 303px;
    height: 232px;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .info__steps-img {
    //width: 648px;
    //height: 426px;
    width: 100%;
    height: 0;
    padding-bottom: 65.74%;
  }

  .info__steps-numbers {
    //width: 303px;
    //height: 232px;
    width: 100%;
    height: 0;
    padding-bottom: 36.57%;
  }

  .info__steps-desc {
    font-size: 16px;
    line-height: 20px;
  }

  .info__steps-column {

    &:nth-child(2) {
      padding: 0 0 0 36px;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 320px) {
  .info__steps-img {
    //width: 648px;
    //height: 426px;
    width: 100%;
    height: 0;
    padding-bottom: 65.74%;
  }

  .info__steps-numbers {
    width: 303px;
    height: 232px;
  }

  .info__steps-desc {
    font-size: 20px;
    line-height: 20px;
  }

  .info__steps-column {
    &:nth-child(2) {
      padding: 0;
      gap: 20px;
    }
  }

  .info__steps-content {
    flex-direction: column;
    gap: 42px;
  }

  .info__steps-container {
    padding-top: 78px;
  }
}
