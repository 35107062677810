.footer__container {
  background-color: $violet-05;
  display: flex;
  transition: 300ms;

  //position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer__content {
  padding-top: 36px;
  padding-bottom: 38px;
  flex: 8;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  color: $gray-95;
}

.footer__links-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
}

.footer__links-column {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.footer__link-title {
  font-family: "PT-Sans-Caption-Bold";
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 18px;
}

.footer__link-subtitle {
  font-family: "PT-Sans-Regular";
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
}

.footer__line {
  display: flex;
  align-self: stretch;
  height: 1px;
  margin-top: 16px;
  opacity: 0.1;
  border: 1px solid $gray-95;
  box-sizing: border-box;
}

.footer__logo-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-self: stretch;
  margin-top: 30px;
}

.footer__logo-year,
.footer__logo-copyright {
  margin-right: 4px;
  font-family: "PT-Sans-Caption-Regular";
}

.footer__link-social {
  display: flex;
  align-items: center;
  font-family: "PT-Sans-Regular";
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 6px;
}

.footer__link-icon {
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.footer__logo-text {
    font-family: "PT-Sans-Caption-Bold";
}

@media screen and (max-width: 1366px) {
  .footer__container {
    width: 100%;
    display: flex;
    //min-width: 1024px;
  }
}

@media screen and (max-width: 1024px) {
  .footer__container {
    width: 100%;
    display: flex;
    //min-width: 768px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 320px) {
  .footer__links-wrapper {
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 24px;
  }
}
