@import "./style-guide";
// Headlines

h1 {
  // color: $violet-05;
  font-family: "PT-Sans-Caption-Bold", sans-serif;
  font-size: 32px;
  line-height: 40px;
  margin: 0;
}

h2 {
  font-family: "PT-Sans-Caption-Bold", sans-serif;
  font-size: 24px;
  line-height: 32px;
  color: #1B1B23;
  margin: 0;
}

h3 {
  font-family: "PT-Sans-Caption-Bold", sans-serif;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

h4 {
  font-family: "PT-Sans-Caption-Bold", sans-serif;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  color: $gray-10;
}

h5 {
  font-family: "PT-Sans-Caption-Bold", sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: $gray-10;
  margin: 0;
  // margin-bottom: 4px;

  &.readonly {
    color: $gray-50;
  }
}

h6 {
  font-family: "PT-Sans-Caption-Regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  margin: 0;
  // color: $gray-10;
}

// Body

.body__text-16 {
  font-family: "PT-Sans-Regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: $gray-10;
}

.body__link {
  font-family: "PT-Sans-Regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: $violet-40;
}

.body__text-14 {
  font-family: "PT-Sans-Regular", sans-serif;
  font-size: 14px;
  line-height: 18px;
}

.body__attention-14 {
  font-family: "PT-Sans-Italic", sans-serif;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
}

.body__tag {
  font-family: "PT-Sans-Regular", sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: $gray-40;
}

///////

.menu__header {
  font-family: "PT-Sans-Caption-Regular";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: $gray-10;
  margin: 0;
}

.form__header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

.form__header-note {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  p{
    font-family: "PT-Sans-Regular";
    font-size: 16px;
    line-height: 20px;
    color: $gray-40;
  }
}

.dot {
  color: $red-90;
}

// Margins

.ml-26{
  margin-left: 26px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-18 {
  margin-bottom: 18px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-42 {
  margin-bottom: 42px;
}

.mb-48 {
  margin-bottom: 48px;
}

.mb-50 {
  margin-bottom: 48px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-66 {
  margin-bottom: 66px;
}

.mb-120 {
  margin-bottom: 120px;
}

