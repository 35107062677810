.info-page__container {
  width: 1800px;
  height: 100%;
  margin: 0 auto;
}

.info-page__up-btn {
  position: fixed;
  bottom: 12px;
  right: 53px;
}

@media screen and (max-width: 1919px) and (min-width: 1366px) {
  .info-page__container {
    width: 1260px;
  }
}

@media screen and (max-width: 1366px) and (min-width: 320px) {
  .info-page__container {
    width: 92%;
  }

  .info-page__up-btn {
    bottom: 12px;
    right: 36px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 320px) {
  .info-page__up-btn {
    bottom: 12px;
    right: 12px;
  }
}
