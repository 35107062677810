@import '../../../../pages/style-guide.scss';

.contact-form__background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.08);
}

.contact-form__container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  top: 50%;
  left: 50%;
  width: 900px;
  padding: 48px 0;
  background: white;
  box-shadow: 0 6px 18px rgba(29, 30, 38, 0.1), 0 3px 12px rgba(29, 30, 38, 0.1);
  border-radius: 4px;
  transform: translate(-50%, -50%);
}

.contact-form__form {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 48px - 48px);
  width: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background: transparent;
    width: 48px;
    z-index: 10000;
    cursor: auto;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-80;
    background-clip: content-box;
    border: 20px solid transparent;
    border-radius: 48px;
    min-height: 30px;
    cursor: default !important;
  }

  &::-webkit-scrollbar-track-piece
  {
    background-color: transparent;
    -webkit-border-radius: 6px;
    cursor: default !important;
  }
}

.contact-form__title {
  margin-bottom: 28px;
  font-family: 'PT Sans Caption', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: $violet-05;
}

.contact-form__close-btn {
  position: absolute;
  top: 12px;
  right: 12px;
}

.contact-form__close-icon {
  &:hover path {
    stroke: $violet-20;
  }

  &:active path {
    stroke: $purple-20;
  }
}

.contact-form__options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 10px;
}

.contact-form__policy {
  margin-top: 16px;
}

.contact-form__comment {
  margin-top: 24px;
}

.contact-form__input {
  margin-bottom: 2px;
}

@media screen and (max-width: 1024px) and (min-width: 320px) {
  .contact-form__container {
    width: 100%;
    max-height: 100vh;
  }

  .contact-form__form {
    width: 80%;
  }
}
