.worker-advantages__container {
  padding: 78px 0 108px 0;
  scroll-margin-top: 78px;
}

.customer-advantages__img.worker-advantages__img {
  background-image: url("../../images/worker-advantages.svg");
}

.worker-advantages__img-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

@media screen and (max-width: 1024px) and (min-width: 320px) {
  .customer-advantages__img.worker-advantages__img {
    margin-top: 30px;
  }
}
