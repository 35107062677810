.recover__container {
  width: 100%;
  display: flex;
  transition: 300ms;
  padding-top: 72px;
  padding-bottom: 444px;
}

.recover__container-large {
  width: 100%;
  height: inherit;
  display: flex;
  transition: 300ms;
  padding-top: 72px;
  padding-bottom: 544px;
  // min-height: 496px;
}

.recover__content {
  transition: 300ms;
  align-items: center;
}

.recover__info {
  display: flex;
  margin-bottom: 30px;
}

.recover__info-icon {
  margin-right: 14px;
}