@import '../../../pages/style-guide.scss';

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  font-family: 'PT Sans Caption', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  transition: 300ms;
}

.main-button{
  background-color: $violet-40;
  color: white;

  &.large {
    padding: 14px 18px;
    height: 48px;
  }

  &.medium {
    padding: 11px 18px;
    height: 42px;
  }

  &.small {
    padding: 9px 12px;
    height: 36px;
  }
}

.main-button:not(:disabled):hover {
  background-color: $violet-20;
  transition: 300ms;
}

.main-button:not(:disabled):active {
  background-color: $purple-20;
}

.secondary-button {
  border: 1px solid $violet-40;
  color: $violet-40;
  transition: 300ms;

  &.large {
    padding: 13px 18px;
    height: 48px;
  }

  &.medium {
    padding: 10px 14px;
    height: 42px;
  }

  &.small {
    padding: 8px 10px;
    height: 36px;
  }
}

.secondary-button:not(:disabled):hover {
  color: $violet-20;
  border: 1px solid $violet-20;
  background-color: $violet-90;
}

.secondary-button:not(:disabled):active {
  color: $purple-20;
  border: 1px solid $purple-20;
  background-color: $purple-95;
}
