.button__google-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $violet-40;
  border-radius: 6px;
  width: 396px;
  height: 48px;
  cursor: pointer;
}

.button__google-icon {
  margin-right: 8px;
}

.button__google-text {
  font-family: "PT-Sans-Caption-Bold";
  letter-spacing: 0.01em;
  color: $violet-40;
  font-size: 16px;
  line-height: 20px;
}
