@import "../src/fonts/InterFonts";
@import "../src/fonts/PTSansFonts";
@import "../src/fonts/PTSansCaptionFonts";

*,
*::before,
*::after {
  box-sizing: border-box;
  caret-color: #1b1b23;
}

body,
html {
  margin: 0;
}

input, textarea {
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 20px;
  font-family: "PT-Sans-Regular", sans-serif;
}

input:focus {
  border-color: #8f90dd;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

div[role="button"]:focus-visible {
  outline: none;
}

div:focus-visible,
canvas:focus-visible {
  outline: none;
}

button {
  -webkit-tap-highlight-color: transparent;
}
