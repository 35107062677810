@import '../../pages/style-guide.scss';

.dropdown__container{
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.dropdown__button{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid $gray-80;
  transition: 300ms;

  &:focus {
    border: 1px solid $violet-40;
  }

  &.large {
    padding: 14px 12px;
    height: 48px;
  }

  &.medium {
    padding: 11px 18px;
    height: 42px;
  }

  &.small {
    padding: 9px 12px;
    height: 36px;
  }
}

.dropdown__list{
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  background-color: #fff;
  z-index: 1;
  transition: 300ms;
  box-shadow: 0 4px 4px rgba(29, 30, 38, 0.05), 0 1px 3px rgba(29, 30, 38, 0.05);
}

.dropdown__list-button{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 12px;
  p{
    font-family: 'PT-Sans-Regular', sans-serif;
    font-size: 16px;
    line-height: 20px;
  }

  &.selected {
    background-color: $violet-90;
  }

  &:not(.selected):hover {
    background-color: $violet-95;
  }

  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.dropdown__placeholder{
  color: $gray-40;
}
