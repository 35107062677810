@import "../../../../pages/style-guide.scss";

.info__header-wrapper {
  position: fixed;
  width: 100%;
  height: 78px;
  box-shadow: 0 4px 4px rgba(29, 30, 38, 0.05),
  0 1px 3px rgba(29, 30, 38, 0.05);
  background-color: white;
}

.info__header-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info__header-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.info__header-link {
  color: $black;
  font-family: "PT-Sans-Caption-Regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
}

.info__header-contacts-btn {
  width: 189px;
}

@media screen and (max-width: 1024px) and (min-width: 320px) {
  .info__header-links {
    display: none;
  }
}
