@import '../../pages/style-guide.scss';

.textarea__container{
  display: flex;
  flex-direction: column;
}

.textarea__content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  background: #ffffff;
  transition: 300ms;

  textarea{
    width: 100%;
    padding: 14px 12px;
    border: 1px solid $gray-80;
    border-radius: 6px;
    color: #1B1B23;
    resize: none;

    &:focus {
      outline: none;
      border: 1px solid $violet-60;
    }

    &::placeholder {
      font-family: 'PT Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $gray-40;

    }

    &::-webkit-scrollbar {
      background: transparent;
      width: 14px;
      z-index: 10000;
      cursor: auto;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-80;
      background-clip: content-box;
      border: 4px solid transparent;
      border-radius: 14px;
      min-height: 30px;
      cursor: default !important;
    }

    &::-webkit-scrollbar-track-piece
    {
      background-color: transparent;
      -webkit-border-radius: 6px;
      cursor: default !important;
    }
  }

  input[type='text']{
    font-size: 16px;
  }
}

.textarea__length {
  font-family: 'PT Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #5E5D67;
}

.input-error{
  border-color: $red-90;
  transition: 300ms;
}

.input__text-error{
  color: $red-90
}
