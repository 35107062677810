@import "../../pages/style-guide.scss";

.header__container {
  background-color: white;
  display: flex;
  box-shadow: 0px 4px 4px rgba(29, 30, 38, 0.05),
    0px 1px 3px rgba(29, 30, 38, 0.05);
  position: fixed;
  z-index: 10;
  width: 100%;
}

.header__content {
  flex: 24;
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: space-between;
}

.header__links {
  display: flex;
  white-space: nowrap;
}

.header__link:not(:first-child) {
  margin-left: 30px;
}

.header__link {
  color: $black;
  font-family: "PT-Sans-Caption-Regular";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
}

.header__logo {
    display: flex;
}

.header__logo-icon {
    position: relative;
    margin-right: 6px;
    bottom: 4px;
}

.header__logo-title {
  color: $violet-05;
  font-family: "PT-Sans-Caption-Bold", sans-serif;
  font-size: 20px;
  line-height: 24px;
}

.header__avatar {
  width: 42px;
  height: 42px;
}

.header__user-info {
  display: flex;
  align-items: center;
  gap: 6px;
}

.header__user-info-wrapper {
  position: relative;
}

.header__user-name {
  font-family: 'PT Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $gray-10;
}

