.info__options-img {
  &.img5 {
    background-image: url("../../images/desiger-options.svg");
  }
}

.lg-contacts-btn {
  display: block;
}

.sm-contacts-btn {
  display: none;
}

@media screen and (max-width: 1919px) and (min-width: 320px) {
  .lg-contacts-btn {
    display: none;
  }

  .sm-contacts-btn {
    display: block;
  }
}
