@import '../../pages/style-guide.scss';

.popup-menu {
  position: absolute;
  padding: 8px 0;
  z-index: 100;
  border-radius: 6px;
  background-color: #fff;
  transition: 300ms;
  box-shadow: 0 4px 4px 4px rgba(29, 30, 38, 0.05), 0 1px 3px 2px rgba(29, 30, 38, 0.05);
}

.user-logout__popup-menu {
  min-width: 221px;
  right: -20px;
  bottom: -50px;
}

.popup-menu-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 12px;
  font-family: 'PT-Sans-Regular', sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: $gray-10;

  &:not(:disabled):hover {
    background-color: $violet-95;
  }

  &:disabled {
    color: $gray-60;
    cursor: default;
  }

  &:not(:disabled):active {
    background-color: $violet-90;
  }
}

