@import '../../pages/style-guide.scss';

.radio-row {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  &:not(:disabled):hover {
    .checked-icon path,
    .checked-icon circle {
      fill: $violet-20;
    }

    .empty-icon path {
      fill: $violet-60;
    }
  }

  &:disabled {
    .empty-icon path {
      fill: $gray-50;
    }

    .radio-label {
      color: $gray-50;
    }
  }
}

.radio-label {
  cursor: pointer;
  font-family: 'PT Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $gray-10;
}

.radio-link {
  display: inline;
  font-family: 'PT Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $violet-40;
  text-decoration: underline;
  text-underline-offset: 2px;

  &:hover {
    font-family: 'PT Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $violet-20;
    text-decoration: underline;
  }
}

.empty-icon {
  width: 24px;
  height: 24px;
}

.radio-content {
  display: block;
  text-align: left;
  flex: 1;
}

