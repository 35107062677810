.divider__horizontal{
    transition: 300ms;
}

@media screen and (min-width: 1921px) {
    .divider__horizontal {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 1920px) and (min-width: 1367px) {
    .divider__horizontal {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 1366px) and (min-width: 1025px) {
    .divider__horizontal {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 1024px) and (min-width: 801px) {
    .divider__horizontal {
        flex: 5;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 800px) and (min-width: 376px) {
    .divider__horizontal {
        flex: 5;
        display: flex;
        // align-items: center;
        // justify-content: center;
    }
}

@media screen and (max-width: 375px) {
    .divider__horizontal {
        flex: 1;
        display: none;
        align-items: center;
        justify-content: center;
    }
}
