.partners-options__container {
  padding: 98px 0 120px 0;
  scroll-margin-top: 78px;
}

.partners-options__content {
  display: flex;
  gap: 48px;
}

.partners-options__block {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  width: 460px;
}

.customer__subtitle.banks-subtitle {
  margin-top: 120px;
  margin-bottom: 36px;
}

.info__options-desc.banks-desc {
  width: 862px;
}

@media screen and (max-width: 1919px) and (min-width: 1366px) {
  .partners-options__block {
    width: 396px;
  }

  .partners-options__content {
    gap: 36px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 320px) {
  .partners-options__content {
    flex-direction: column;
    gap: 12px;
  }

  .partners-options__block {
    width: 100%;
  }

  .info__options-desc.banks-desc {
    width: 100%;
  }
}
