@import "./style-guide";
@import "./textStyles";
@import "../components/Header/headerStyle.scss";
@import "../components/Footer/footerStyle.scss";
@import "../pages/Login/loginStyle.scss";
@import "../pages/Registration/registrationStyle.scss";
@import "../pages/Recover/recoverStyle.scss";
@import "ProfileCustomer/profile";
@import "../components/Buttons/GoogleButton/googleButtonStyle.scss";
@import "../components/SideMenu/sideMenuStyle.scss";
@import "../components/Buttons/Button/buttonStyle.scss";
@import "../components/Dropdown/dropdownStyle.scss";
@import "../components/Label/labelStyle.scss";

html {
  height: 100%;
}

textarea:focus, input:focus{
  outline: none;
}

#root {
  display: flex;
  flex-direction: column;
  // height: 100%;

  position: relative;
  min-height: 100%;
}

body {
  flex: 1;
  width: 100%;
  height: 100%;
}

body,
p {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

a:active,
a:hover,
a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
}

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 396px;
}

.form__input {
  position: relative;
  margin-bottom: 30px;
}

.form__input-item {
  box-sizing: border-box;
  width: 396px;
  height: 48px;
  background: #ffffff;
  border: 1px solid $gray-80;
  border-radius: 6px;
}

.eye-icon {
  position: absolute;
  top: 35%;
  right: 2%;
  cursor: pointer;
}

.eye-icon-low {
  position: absolute;
  top: 48%;
  right: 2%;
  cursor: pointer;
}

.checkbox {
  margin-right: 15px;
}
