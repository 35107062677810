@import "../style-guide.scss";

.registration__container {
  width: 100%;
  display: flex;
  transition: 300ms;
  padding-top: 72px;
  // padding-bottom: 72px;
  padding-bottom: 316px;
}

.registration__container-large {
  width: 100%;
  display: flex;
  transition: 300ms;
  padding-top: 72px;
  padding-bottom: 444px;
}

.registration__content {
  display: flex;
  flex-direction: column;
  transition: 300ms;
  align-items: center;
}

.registration__header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.registration__title {
  color: $violet-05;
  font-family: "PT-Sans-Caption-Bold";
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 60px;
}


.registration__checkbox {
  display: flex;
  align-items: start;
  // margin-bottom: 26px;
}

.registration__checkbox-input {
  margin-right: 15px;
}

.registration__input-icon {
  position: absolute;
  top: 28%;
  right: 2%;
}

.registration__input-link {
  text-decoration: underline;
  color: $violet-40;
}

.registration__info {
  display: flex;
}

.registration__info-icon {
  margin-right: 14px;
}

.registration__select__container{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 72px 0;
}

.registration__select__content{
  width: 612px;
}

.registration__select-card{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $gray-98;
  padding: 54px 108px;
  border-radius: 6px;
  width: 100%;
  &:first-child{
    margin-bottom: 60px;
  }
  box-shadow: 0px 6px 12px rgba(29, 30, 38, 0.1), 0px 3px 6px rgba(29, 30, 38, 0.1);
}

.registration__select-card-title{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 18px;
}

.registration__select-card-button{
  width: 100%;
}

.registration__select-card-dropdown{
  width: 100%;
}
