$black: #212121;

//Gray
$gray-98: #F9F9FA;
$gray-95: #F5F4F8;
$gray-90: #E4E2EB;
$gray-80: #C8C5D0;
$gray-70: #ACAAB4;
$gray-60: #918F9A;
$gray-50: #777680;
$gray-40: #5E5D67;
$gray-30: #47464F;
$gray-20: #303038;
$gray-10: #1B1B23;
$gray-05: #18181C;

// Violet Primary
$violet-95: #FCF8FF;
$violet-90: #E1DFFF;
$violet-60: #8F90DD;
$violet-40: #686ACA;
$violet-20: #3C3C8F;
$violet-05: #111644;

// Blue Secondary
$blue-95: #FCF8FF;
$blue-90: #CCE5FF;
$blue-60: #92CCFF;
$blue-40: #3598D9;
$blue-20: #006497;
$blue-05: #003351;

// Purple Secondary
$purple-95: #F8EDFF;
$purple-90: #D7BAFF;
$purple-60: #A879EC;
$purple-40: #7445B4;
$purple-20: #4F1B8E;
$purple-05: #290055;

// Green Additional
$green-95: #F8EDFF;
$green-90: #A3DDB3;
$green-60: #5A9D6C;
$green-40: #476C51;

// Yellow Additional
$yellow-95: #E3FEEB;
$yellow-90: #FFE483;
$yellow-60: #F9D51C;
$yellow-40: #DC9A1B;

// Red Additional
$red-95: #E3FEEB;
$red-90: #BA1A1A;
$red-60: #93000A;
$red-40: #410002;