@import "../../../../pages/style-guide.scss";

.customer-options__container {
  padding: 66px 0 72px 0;
  background-color: $gray-98;
}

.customer-options__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 42px;
}

.customer-options__content-column {
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.customer-options__content-column-top {
  display: flex;
  flex-direction: column;
}

.info__options-number {
  font-family: 'PT Sans Caption', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: $violet-40;
}

.info__options-subtitle {
  margin-bottom: 12px;
  font-family: 'PT Sans Caption', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: $gray-10;
}

.info__options-block {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.info__options-desc {
  font-family: 'PT Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: $gray-10;
}

.info__options-img {
  width: 840px;
  height: 547px;
  background-position: -20px 24px;
  background-repeat: no-repeat;
  background-size: contain;

  &.img1 {
    background-image: url("../../images/customer-options-img1.svg");
  }

  &.img2 {
    background-image: url("../../images/customer-options-img2.svg");
  }
}

.info__contacts-btn {
  width: 288px;
  display: flex;
  justify-self: flex-end;
}

@media screen and (max-width: 1919px) and (min-width: 1366px) {
  .customer-options__content-column {
    width: 540px;
  }

  .info__options-img {
    background-position: -20px 14px;
    width: 570px;
    height: 372px;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .customer-options__content-column {
    width: 450px;
  }

  .info__options-img {
    background-position: -20px 14px;
    width: 470px;
    height: 307px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 320px) {
  .customer-options__content {
    flex-direction: column;
  }

  .customer-options__content-column {
    width: 100%;
  }

  .info__options-img {
    background-position: -20px 14px;
    width: 100%;
    height: 0;
    padding-bottom: 65.32%;
  }


  .info__contacts-btn {
    margin-top: 48px;
  }
}
