@import '../../pages/style-guide.scss';

.label__container{
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}

.label__h5{
  display: flex;
  flex-direction: row;
}

.laber__required{
  color: $red-90;
}